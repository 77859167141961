<template>
  <main class="home">
    <section class="header">
      <div class="left"
           :style="{'background-image': 'url(' + require('@/assets/img/women/8_marina/pics/Women-of-georgia-Marina-3.jpg') + ')'}"></div>
      <div class="right">
        <div class="titles">
          <h1><b>Women</b> of Georgia</h1>
          <span>An intimate look into Georgian Womanhood</span>
        </div>
      </div>
    </section>
    <section class="home-carroussel">
      <div class="carroussel" id="autoScroll" @mouseenter="isHover = true" @mouseleave="isHover = false">
        <div class="slide" v-for="item in women" :key="item.index">
          <router-link class="carroussel-link"
                       :to="{name : 'women', params: { womenName: item.name.toLowerCase(), womenId: item.order } }">
          </router-link>
          <div class="pic-bg"
               :style="{'background-image': 'url(' + require('@/assets/img/carroussel/' + item.pic) + ')'}"></div>
          <div class="bg"></div>
          <div class="content">
            <div class="story-index">Story {{item.order}}</div>
            <h2>{{item.name}}</h2>
            <p>{{item.desc}}</p>
            <div class="slide-button-read" :style="{'color': '#' + item.color}">
              Read the story
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="context">
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-xs-12 col-md-8 col-md-offset-2">
            <div class="project-block">
              <div class="text-center title">
                <!--                <span class="subtitle">WHERE THE IDEA CAME FROM</span>-->
                <h1>The Context</h1>
              </div>
              <p>
                This project seeks to shed light on a country and culture that remains largely overlooked in France and
                in the world. Georgia is a small territory of approximately 3 million inhabitants, tucked away in the
                heart of the Caucasus, bordering Russia and Turkey. The country is still marked by occupation, conflict
                and traditional values though it is somewhat rapidly anchoring itself in modernity and change through
                its burgeoning youth culture.
              </p>
              <p>
                Georgia is imbued with stories of women who are strong and respected, much like the history of the
                female ‘King’ Tamar - the most illustrious of all Georgian monarchs who led her country to its height
                during the 13th century. Nonetheless, women often find themselves relegated to a role that can be
                considered as secondary. This project, however, tends to find that Georgian women occupy a preponderant
                role in society even if it is sometimes done in the shadows. In Georgian society, women are often the
                driving force in their community, and besides their role as wives and mothers, they often feel invested
                with the mission of cultivating, promoting and enriching a much larger circle. They are the guardians
                and sponsors of a proper functioning and thriving community.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="project">
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-xs-12 col-md-8 col-md-offset-2">
            <!--            CONTEXT-->
            <div class="project-block">
              <div class="text-center title">
                <!--                <span class="subtitle">ABOUT THE PROJECT</span>-->
                <h1>The Project</h1>
              </div>
              <div class="content">
                <p>We sought to collect testimonies from women of all ages from diverse backgrounds in order to better
                  understand the diversity that exists within the country – a country in which a new generation is
                  fighting against the traditional values of an older generation, and life is branded by the dichotomy
                  that exists between the privileged city-dwellers of Tbilisi, the country’s capital, and those who
                  reside
                  in a still very rural environment.
                </p>
                <p>We have followed these women through a day in their life as they have unlocked the door to their
                  stories. Through these portraits, we have tried to amplify the voices of 10 Georgian women who bear
                  witness to the central role Georgian women play in a patriarchal and orthodox society marked by civil
                  wars and Russia’s grip.</p>
                <p>From the spontaneous nature of their testimonies ensues touching anecdotes – life stories where these
                  women talk about their daily life and give their opinion on women’s issues in Georgia.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="itinerary">
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-xs-12 col-md-8 col-md-offset-2">
            <div class="text-center title">
              <h1>Our Itinerary</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="dispatcher">
        <div class="map">
          <img :src="require('@/assets/img/project/map.png')" alt="The Itinerary">
        </div>
        <div class="destination">
          <div class="destination-list">
            <div class="destination--item"><span>Tbilisi</span> Capital</div>
            <div class="destination--item"><span>Lanchkhuti</span> Guria</div>
            <div class="destination--item"><span>Tbilisi</span> Capitale</div>
            <div class="destination--item"><span>Savane</span> Shida Kartli</div>
            <div class="destination--item"><span>Baghdati</span> Imereti</div>
            <div class="destination--item"><span>Poti</span> Samegrelo</div>
            <div class="destination--item"><span>Ninotsminda</span> Samtskhe-Javakheti</div>
            <div class="destination--item"><span>Marneuli</span> Kvemo Kartli</div>
            <div class="destination--item"><span>Tbilisi</span> Capital</div>
            <div class="destination--item"><span>Nigvziani</span> Guria</div>
          </div>
        </div>
      </div>
    </section>
    <section class="personal-notes"
             :style="{'background-image': 'url(' + require('@/assets/img/project/Eva_Isabelle.jpg') + ')'}">
      <div class="bg"></div>
      <div class="container" data-aos="fade-up">
        <div class="row">
          <div class="col-xs-12 col-md-8 col-md-offset-2">
            <div class="text-center title">
              <h1>Personal notes</h1>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xs-12 col-sm-6">
            <h2>Eva</h2>
            <span class="couture">Photographer, co-creator</span>
            <p>In Georgia, I have met women who consciously or unconsciously are all feminists.Through their strength of
              character and their actions, they are trying to inspire other women living in this patriarchal society.
            </p>
            <p>Some of these women are young, unmarried and childless. They wear their feminist badge proudly. Some of
              these women are also more advanced in age, and for them the word ‘feminism’ is still linked to behaviours
              that are deemed too radical.</p>
            <p>For a small minority, they have lived through war, several wars even, and understandably a feminist fight
              has never really been relevant.For the younger generation, those who are allowed to be a little more
              carefree, they claim their feminist attachment with pride.</p>
            <p>Another project around feminism you’ll tell me. Yes, another one, because feminism is not a trend. It is
              a movement in which all voices count and contribute to the growth of the cause. “Stories are data with a
              soul” Brene Brown once said . Each new voice is another added stone in this big and powerful bridge
              leading to the empowerment of women.</p>
            <p>This project illustrates the opinion of 10 Georgian women. Through listening to their words and finding
              common ground in our struggles, they have allowed me to unleash my own voice, and my own power.</p>
            <p>Through the echo of our shared experience, these women have peeled back the veil that was unknowingly
              hanging over my eyes, preventing me from seeing patriarchal oppression in my own country. Yes, sexism here
              takes on another form. It may be less tangible, less ostentatious, but it is still a reality of everyday
              life. I am now able to perceive certain behaviours that did not previously seem problematic. Certain words
              that merely trickled over me before have now taken the forms of arrows piercing through my existence and
              igniting my outrage.</p>
            <p>These women have confided in us for a day and I thank them from the bottom of my heart. Beyond the fact
              that they have shared their story and their point of view, that have allowed us to enter into their home,
              their family and their culture.</p>
          </div>
          <div class="col-xs-12 col-sm-6">
            <h2>Isabelle</h2>
            <span class="couture">Author, co-creator</span>
            <p>There are many ways in which living in the country of Georgia for over 2 years has changed me. There are
              many things that I have been amazed by and many more that I have learned. But my single greatest treasure
              lies within the connections I have made with the women I have met, talked to, worked with, lived with and
              became friends with. Coming from a western culture where individualism ideologically takes precedence over
              collectivism, I have been inspired by the many great sacrifices Georgian women make on a daily for the
              benefit and progress of their country and communities, all this without much recognition. From my
              inherently limited and biased perspective, I have come to understand that the well-being of their
              communities expectedly laid on their shoulders and that their - socially constructed and sanctioned -
              nurturing nature was often taken for granted. However this project is not about my point of view, but
              theirs - the women that have opened their home and shared their stories with us.</p>
            <p>These women never needed us to give them a voice. I started this collaborative piece of work primarily
              for my own personal growth and understanding. What you will read are their unedited words to the
              questions
              I had for myself. My hope for this project is that you can learn from these women the same way I have
              learned from them. That you can be inspired by their thoughts the same way they have inspired me. That you
              can take their trials and triumphs, and apply them to your own situation through lessons learned. That you
              can add a new lens through which you view things. I hope this project will pique your interest in the
              country, history and culture that is Georgia. Finally, I hope that this project will invite you to dive
              deeper into the question of feminism and the infinite forms it can take.</p>

          </div>
        </div>
      </div>
      <div class="thanks">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <p>
                We, the creators, are extremely grateful towards Tristan for creating this website and allowing our
                project to come to life.
              </p>
              <p>
                We would also like to extend our gratitude to Nanuka Maisashvili, Rachael Rosenberg, Griffith Ridgeway
                and Ravil Gasanov for helping us to overcome the language barrier with some of our participants.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { data } from '@/data';

export default {
  name: 'Home',

  components: {},

  data () {
    return {
      women: data,
      isHover: false,
    }
  },

  computed: {},
  methods: {},
  created () {

  },

  mounted () {
    const domElement = document.getElementById('autoScroll');
    const elementScrollWith = domElement.scrollWidth;

    self.setInterval(() => {
      if (!this.isHover && domElement.scrollLeft !== elementScrollWith) {
        domElement.scrollTo(domElement.scrollLeft + 1, 0);
      }
    }, 50);

  }
}
</script>

<style lang="scss">
  #app {
    @media (max-width: $break-tablet) {

      section.header {
        height: 50vh;

        .left {
          display: none;
        }

        .right {
          h1 {
            font-size: 5rem;
            line-height: 5rem
          }

          span {
            color: rgba(black, .45);
            font-size: 1.5rem;
          }
        }
      }
    }
  }

  section.header {
    display: flex;
    justify-content: center;
    height: calc(100vh - 50px);

    .left, .right {
      flex: 1 1 50%;
    }
  }

  .left {
    background-size: cover;
    background-position: center;
  }

  .right {
    display: flex;
    justify-content: center;
    align-items: center;

    .titles {
      text-align: center;
      max-width: 600px;
    }


    h1 {
      font-family: 'Couture', Helvetica, Arial, sans-serif;
      font-size: 9rem;
      line-height: 8rem;
      margin-bottom: 0;

      b {
        color: $green;
      }
    }

    span {
      color: rgba(black, .45);
      font-size: 1.9rem;
      margin-top: -.5rem;
    }
  }

  section.home-carroussel {

    .carroussel {
      display: flex;
      overflow-x: scroll;
      -webkit-transition-property: -webkit-scrolling;
      -webkit-transition-duration: 0.5s;
      -webkit-transition-delay: 0.1s;
    }

    .slide {
      flex: 0 0 70%;
      cursor: pointer;
      height: 20vw;
      min-height: 500px;
      overflow: hidden;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      position: relative;

      @media (min-width: $break-phone) {
        flex: 0 0 40%;
      }

      @media (min-width: $break-desktop) {
        flex: 0 0 22%;
      }

      @media (min-width: 1600px) {
        flex: 0 0 15%;
      }

      @media (min-width: 2200px) {
        flex: 0 0 10%;
      }

      &:hover {
        .slide-button-read {
          color: white;
          transform: translateX(3px);
        }

        .pic-bg {
          transform: scale(1.05);
        }
      }


      .pic-bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        transition: all 300ms ease-in-out;
      }

      .carroussel-link {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
      }

      .slide-button-read {
        display: block;
        font-family: 'Couture', Helvetica, Arial, sans-serif;
        margin-top: 10px;
        padding: 1rem 1rem 1rem 0;
        color: $green;
        transition: all 100ms ease-in-out;

        &:hover {
          color: white;
          transform: translateX(3px);
        }
      }

      .bg {
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.80) 100%);
        position: absolute;
        height: 70%;
        bottom: 0;
        width: 100%;
        z-index: 0;
      }

      .content {
        flex: 0 0 auto;
        padding: 2rem;
        position: relative;
        z-index: 1;

        .story-index {
          color: white;
          margin-bottom: 5px;
          text-transform: uppercase;
          font-family: 'Couture', Helvetica, Arial, sans-serif;
          font-size: 1.2rem;
        }

        h2 {
          display: inline-block;
          background: white;
          font-size: 2rem;
          line-height: 2rem;
          font-family: 'Couture', Helvetica, Arial, sans-serif;
          padding: .8rem .8rem .2rem .8rem;
          margin-bottom: 10px;
        }

        p {
          font-size: 1.5rem;
          line-height: 2.5rem;
          color: white;
          margin-bottom: 0;
        }

      }
    }

  }

  section.context, section.project, section.itinerary, section.personal-notes {
    position: relative;
    padding: 8rem 0;
    z-index: 1;
  }

  section.project {
    background: $green-bg;

    .project-block {
    }
  }

  section.itinerary {
    background: black;
    min-height: 50vh;

    .title h1 {
      color: white;
    }

    .dispatcher {
      display: flex;
      flex-direction: column;

      @media (min-width: $break-tablet) {
        flex-direction: row;
        margin-top: 10vh;
        margin-bottom: 10vh;
      }

      .map {
        flex: 1 1 75%;
      }

      .destination {
        flex: 1 1 25%;
      }
    }

    .map {

      img {
        width: 100%;
      }
    }

    .destination {
      display: flex;
      align-items: center;
      text-align: center;

      .destination-list {
        width: 100%;
        margin-top: 4rem;

        @media (min-width: $break-tablet) {
          margin-top: 0;
        }
      }

      &--item {
        color: rgba(white, .2);
        font-size: 1.2rem;
        margin-bottom: 1rem;
        font-family: 'Couture', Helvetica, Arial, sans-serif;

        span {
          font-family: 'Merriweather', Helvetica, Arial, sans-serif;
          color: white;
          font-size: 2.2rem;
          font-weight: bold;
          margin-right: 5px;
        }
      }
    }
  }

  section.personal-notes {
    background-size: cover;
    background-position: top;
    position: relative;
    color: white;
    padding-bottom: 150vh !important;
    padding-top: 20vh !important;

    &:after {
      display: block;
      position: absolute;
      content: '';
      top: 0;
      width: 100%;
      height: 100vh;
      background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 1) 100%);
      z-index: -1;
    }

    .title h1 {
      color: white;
      margin-bottom: 8rem;
      position: static;
    }

    .bg {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(black, .6);
    }

    h2 {
      margin-bottom: 0;
      color: white;
    }

    span {
      color: rgba(white, .6);
      font-size: 1.2rem;
      margin-bottom: 2rem;
      display: block;
    }

    p {
      color: white;
      margin-bottom: 1.4rem;
    }


    .thanks {
      padding: 3rem 0;
      text-align: center;
      position: absolute;
      width: 100%;
      bottom: 0;

      p {
        font-size: 1.4rem;
        line-height: 2.2rem;
      }
    }
  }

</style>
