export const data = [
  {
    "order": 1,
    "color": "8F90AD",
    "pic": "nutsa.jpg",
    "name": "Nutsa",
    "infos": "20 years old, from Tbilisi",
    "desc": "Many girls don’t know they have choices and options; getting married at an early age is the only way they know how to live",
  },
  {
    "order": 2,
    "color": "2B4E8A",
    "pic": "khatuna.jpg",
    "name": "Khatuna",
    "infos": "40 years old, from Poti",
    "desc": "As a woman, you need to have more strength, more education, more motivation because if you don’t, it will not be easy to find your place in society"
  },
  {
    "order": 3,
    "color": "B2D8A7",
    "pic": "natia.jpg",
    "name": "Natia",
    "infos": "43 years old, from Lanchkhuti",
    "desc": "The problem is that in Georgia popularity and reputation is everything"
  },
  {
    "order": 4,
    "color": "D2BBC2",
    "pic": "salome.jpg",
    "name": "Salome",
    "infos": "16 years old, from Savane",
    "desc": "We have no desire in repeating the same things over and over again just because these boys are incapable of seeing the issue"
  },
  {
    "order": 5,
    "color": "D9554E",
    "pic": "irina.jpg",
    "name": "Irina",
    "infos": "30 years old, from Ninostminda*",
    "desc": "Life here is full of stereotypes, but people think these stereotypes are traditions"
  },
  {
    "order": 6,
    "color": "828F9E",
    "pic": "nargizi.jpg",
    "name": "Nargizi",
    "infos": "70 years old, from Samtredia",
    "desc": "According to Georgian tradition, men shouldn’t participate in household chores. I don’t think this is normal"
  },
  {
    "order": 7,
    "color": "C14B17",
    "pic": "manana.jpg",
    "name": "Manana",
    "infos": "50 years old, from Baghdati",
    "desc": "When I met my husband, he asked me: \"why do you look people in the eyes when you talk to them?\""
  },
  {
    "order": 8,
    "color": "5E2E5B",
    "pic": "marina.jpg",
    "name": "Marina",
    "infos": "60 years old, from Tbilisi",
    "desc": "I remember this girl who was my classmate, when people would ask her father how many children he had, he would say: \"I have one child and two girls\""
  },
  {
    "order": 9,
    "color": "BC1C27",
    "pic": "anuka.jpg",
    "name": "Anuka",
    "infos": "24 years old, from Tbilisi",
    "desc": "Here, men should be sexually active at a very early age and women should be virgins if they are not married. But then who are these men having sex with?"
  },
  {
    "order": 10,
    "color": "D69187",
    "pic": "leila.jpg",
    "name": "Leila",
    "infos": "45 years old, from Marneuli",
    "desc": "People here will support “women” if it is a hypothetical woman that they don’t know, but if it’s someone they are close to, they will not support her"
  },
]

export const books = [
  {
    "title": "Me, Margarita",
    "author": "Ana Kordzaia-Samadashvili",
    "description": "Short stories about men and women, love and hate, sex and disappointment, cynicism and hope’ The Georgian Margarita is becoming one of the strongest female literary figures.",
    "pic": "me_margarita"
  },
  {
    "title": "Gender in Georgia: A feminist perspective on culture, nation and history in the south Caucasus",
    "author": "Maia Barkaia",
    "description": "In Gender in Georgia, editors Maia Barkaia and Alisse Waterston bring together an international group of feminist scholars to explore the socio-political and cultural conditions that have shaped gender dynamics in Georgia from the late 19th century to the present.",
    "pic": "gender_in_georgia"
  },
  {
    "title": "Georgian Cuisine and Tried Housekeeping notes",
    "author": "Barbare Jorjadze",
    "description": "Barbare Jorjadze was a Georgian writer, women’s rights activist, and author of the XIX century.  Her first cookbook was published in 1874, in Georgia.",
    "pic": "georgian_cuisine"
  },
  {
    "title": "Ali and Nino: a love story",
    "author": "Kurban Said",
    "description": "A romantic tale of young love and political upheaval in Central Asia divided by separate cultures.",
    "pic": "ali_and_nino"
  },
]

export const movies = [
  {
    "title": "In bloom",
    "director": "Nana Ekvtimishvili",
    "link": "https://www.youtube.com/watch?v=IcmUjndPV8k&ab_channel=PalaceFilms",
    "pic": "in_bloom"
  },
  {
    "title": "And then we danced",
    "director": "Levan Akin",
    "link": "https://www.youtube.com/watch?v=GmjDLJ7dkHg&ab_channel=PeccadilloPictures",
    "pic": "and_then_we_danced"
  },
  {
    "title": "My happy family",
    "director": "Nana Ekvtimishvili",
    "link": "https://www.youtube.com/watch?v=s9j8MvV7tE0&ab_channel=MIFF",
    "pic": "my_happy_family"
  },
  {
    "title": "Scary mother",
    "director": "Ana Urushadze",
    "link": "https://www.youtube.com/watch?v=mret5IrM7FY&ab_channel=Geomovies",
    "pic": "scary_mother"
  },
]

export const artists = {
  painters: [
    {
      "name": "Vera Pagava",
      "kind": "Painter",
      "link": "https://www.instagram.com/verapagava_art/",
      "pic": "vera_pagava"
    },
    {
      "name": "Elene Akhvlediani",
      "kind": "Painter",
      "link": "https://www.baiagallery.ge/en/project/elene-akhvlediani/",
      "pic": "elene_akhvlediani"
    },
    {
      "name": "Eteri Chkadua",
      "kind": "Painter",
      "link": "http://www.eterichkadua.com/",
      "pic": "eteri_chkadua"
    }
  ],

  photographers: [
    {
      "name": "Dina Oganova",
      "kind": "Photographer",
      "link": "http://dikarka.ge/personal-projects/metoo/",
      "pic": "dina_oganova"
    },
    {
      "name": "Natela Grigalashvili",
      "kind": "Photographer",
      "link": "http://www.natelagrigalashvili.com/",
      "pic": "natela_grigalashvili"
    },
    {
      "name": "Anka Gujabidze",
      "kind": "Photographer",
      "link": "https://artpil.com/anka-gujabidze/",
      "pic": "anka_gujabidze"
    }
  ],
}

